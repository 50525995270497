<template>
  <div>
    <v-layout v-if="itemIsSVG" align-start>
      <div class="qr-code" v-html="item.Value"></div>
      <v-layout column>
        <v-btn
          v-if="
            !$helpers.itemNeedsDisable(
              item.ReadOnly,
              needsDisable,
              editItemAdmin
            )
          "
          depressed
          icon
          color="primaryred"
          @click="item.Value = ''"
          class="ml-2"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          color="primary"
          @click="downloadSVG(item.Value)"
          class="ml-2"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-layout>
    </v-layout>
    <div v-else>
      <v-text-field
        :disabled="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
        v-model="item.Value"
        :label="$t('Value')"
        outlined
        dense
      >
      </v-text-field>
      <v-btn
        :disabled="
          $helpers.itemNeedsDisable(
            item.ReadOnly,
            needsDisable,
            editItemAdmin
          ) || !item.Value
        "
        depressed
        color="primary"
        @click="makeQrCode()"
        :class="$store.getters.editMode ? 'mb-4' : ''"
        >{{ $t("Make QR-code") }}</v-btn
      >
    </div>
    <template v-if="$store.getters.editMode">
      <hr />

      <v-row no-gutters class="my-5">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
        >
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              :label="$t('Column width')"
              outlined
              dense
            ></v-text-field>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.RemoveCard"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Remove frame')"
            ></v-switch>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },

  computed: {
    itemIsSVG() {
      const parser = new DOMParser();
      try {
        const doc = parser.parseFromString(this.item.Value, "image/svg+xml");
        return doc.documentElement.nodeName.toLowerCase() === "svg";
      } catch (e) {
        return false;
      }
    },
  },
  methods: {
    makeQrCode() {
      this.$request.get(
        "/content/getqrcode?value=" + this.item.Value,
        null,
        (res) => {
          this.item.Value = res.item;
        }
      );
    },

    downloadSVG(svgContent) {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");

      // Create the filename using the current date and time
      const fileName = `${year}${month}${day}_${hours}${minutes}.svg`;
      // Create a new blob with the SVG content
      const blob = new Blob([svgContent], { type: "image/svg+xml" });

      // Create a URL object from the blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      // Trigger a click event on the link
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    },
  },
};
</script>

<style scoped>
.qr-code {
  height: 10rem;
  width: 10rem;
}
</style>