var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.itemIsSVG)?_c('v-layout',{attrs:{"align-start":""}},[_c('div',{staticClass:"qr-code",domProps:{"innerHTML":_vm._s(_vm.item.Value)}}),_c('v-layout',{attrs:{"column":""}},[(
          !_vm.$helpers.itemNeedsDisable(
            _vm.item.ReadOnly,
            _vm.needsDisable,
            _vm.editItemAdmin
          )
        )?_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","icon":"","color":"primaryred"},on:{"click":function($event){_vm.item.Value = ''}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadSVG(_vm.item.Value)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)],1):_c('div',[_c('v-text-field',{attrs:{"disabled":_vm.$helpers.itemNeedsDisable(_vm.item.ReadOnly, _vm.needsDisable, _vm.editItemAdmin),"label":_vm.$t('Value'),"outlined":"","dense":""},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}),_c('v-btn',{class:_vm.$store.getters.editMode ? 'mb-4' : '',attrs:{"disabled":_vm.$helpers.itemNeedsDisable(
          _vm.item.ReadOnly,
          _vm.needsDisable,
          _vm.editItemAdmin
        ) || !_vm.item.Value,"depressed":"","color":"primary"},on:{"click":function($event){return _vm.makeQrCode()}}},[_vm._v(_vm._s(_vm.$t("Make QR-code")))])],1),(_vm.$store.getters.editMode)?[_c('hr'),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
            ? _vm.item.colWidth < 5
              ? 12
              : 4
            : _vm.item.colWidth < 10
            ? 4
            : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }